import { useForm } from 'vee-validate'
import { professionalInformationSchema } from 'account/validation-schema/creator'

import type {
  CreatorAction,
  CreatorDescAction,
} from 'account/types/creator-actions'
import type {
  AddressAction,
  EditAddressAction,
  Action,
} from 'account/types/actions'
import type { AttributeItem } from '@/types/variant'

export const useCreatorProfessionalInformationStore = defineStore(
  'creatorProfessionalInformationStore',
  () => {
    const creatorStore = useCreatorStore()
    const customerStore = useCustomerStore()
    const form = useForm({
      validationSchema: professionalInformationSchema,
    })

    const [desc] = form.useFieldModel(['desc'])
    const [firstName] = form.useFieldModel(['firstName'])
    const [lastName] = form.useFieldModel(['lastName'])
    const [country] = form.useFieldModel(['country'])
    const [state] = form.useFieldModel(['state'])
    const [city] = form.useFieldModel(['city'])
    const [address] = form.useFieldModel(['address'])
    const [apartment] = form.useFieldModel(['apartment'])
    const [postCode] = form.useFieldModel(['postCode'])

    const { isSubmitted: isFormSubmitted } = useValidation(form.submitCount)

    const addressId = computed(() => customerStore?.customer?.addresses[0]?.id)
    const setAddressStructure = () => {
      if (addressId.value) {
        return reactive({
          action: 'changeAddress',
          addressId: addressId.value as string,
          address: {
            firstName: firstName.value as string,
            lastName: lastName.value as string,
            country: country.value as string,
            city: city.value as string,
            streetName: address.value as string,
            apartment: (apartment.value || '') as string,
            postalCode: postCode.value as string,
          },
        })
      } else {
        return reactive({
          action: 'addAddress',
          address: {
            firstName: firstName.value as string,
            lastName: lastName.value as string,
            country: country.value as string,
            city: city.value,
            streetName: address.value as string,
            apartment: (apartment.value || '') as string,
            postalCode: postCode.value as string,
          },
        })
      }
    }
    const getSavedProfessionalInformation = () => {
      if (!creatorStore.creator) {
        return []
      }
      const customerAddress = customerStore.customer?.addresses[0]
      const attributes: AttributeItem[] =
        creatorStore?.creator?.masterData?.current?.masterVariant?.attributes
      const country =
        attributes?.find((attr) => attr.name === 'location_country')?.value ||
        null
      const city =
        attributes?.find((attr) => attr.name === 'location_city')?.value || null
      const state = attributes?.find((attr) => attr.name === 'location_state')
        ?.value

      return {
        address: customerAddress?.streetName,
        apartment: customerAddress?.apartment,
        city,
        country,
        state,
        desc: creatorStore.creator?.masterData?.current?.description?.['en-US'],
        firstName: customerAddress?.firstName,
        lastName: customerAddress?.lastName,
        postCode: customerAddress?.postalCode,
      }
    }

    const updateCustomerData = async () => {
      const actions: Action[] = [
        setAddressStructure() as AddressAction | EditAddressAction,
      ]
      return await customerStore.updateAccount(actions, {
        notify: false,
        prefetchData: true,
      })
    }

    const updateCreatorProfile = async () => {
      const updateActions = []
      const descAction: CreatorDescAction = {
        action: 'setDescription',
        staged: false,
        description: {
          'en-US': desc.value as string,
        },
      }
      const cityToApi: CreatorAction = {
        action: 'setAttributeInAllVariants',
        staged: false,
        name: 'location_city',
        value: city.value as string,
      }
      const countryToApi: CreatorAction = {
        action: 'setAttributeInAllVariants',
        staged: false,
        name: 'location_country',
        value: country.value as string,
      }
      const stateToApi: CreatorAction = {
        action: 'setAttributeInAllVariants',
        staged: false,
        name: 'location_state',
        value: state.value as string,
      }

      updateActions.push(descAction)
      updateActions.push(cityToApi)
      updateActions.push(countryToApi)
      updateActions.push(stateToApi)

      return await creatorStore.updateProfile(updateActions, {
        prefetchData: true,
      })
    }

    const updateProfessionalInformation = async () => {
      const isCustomerUpdated = await updateCustomerData()
      const isCreatorUpdated = await updateCreatorProfile()
      if (isCreatorUpdated) {
        form.resetForm({ values: getSavedProfessionalInformation() })
      }
      const statuses = [isCustomerUpdated, isCreatorUpdated]
      const { $notify } = useNuxtApp()
      if (statuses.every((el) => el)) {
        $notify('Additional Information updated successfully')
        return true
      } else {
        $notify({
          text: 'Failed to update creator data',
          severity: 'error',
        })
        return false
      }
    }

    return {
      address,
      apartment,
      city,
      country,
      state,
      desc,
      form,
      getSavedProfessionalInformation,
      isFormSubmitted,
      postCode,
      updateProfessionalInformation,
    }
  },
)
